import { useQuery } from "@tanstack/react-query";

import { ListHardcodedSensorResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/sensors/hardcoded";

export function useHardcodedSensors() {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["hardcoded-sensors"],
    queryFn: async () => {
      const response =
        await apiClient.get<ListHardcodedSensorResponse>(baseUrl);

      return response.data.sensors;
    },
  });

  return {
    sensors: data,
    error,
    isLoading,
  };
}
