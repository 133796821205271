import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  AccountType,
  EstimateReport,
  EstimateReportResponse,
} from "@revv/data";
import { mapEstimateReportResponse } from "@revv/utils";

import { useStateContext } from "./context";
import { useCustomer } from "./useCustomer";
import { useWorkOrderStatuses } from "./useWorkOrderStatuses";

export function useEstimateReport(
  estimateId: string,
  decryptData: (data?: unknown) => EstimateReportResponse
) {
  const queryClient = useQueryClient();
  const { userContext } = useCustomer();
  const { apiClient } = useStateContext();
  const { defaultWorkOrderStatus } = useWorkOrderStatuses();

  const queryKey = ["report", estimateId, userContext];

  const { data, isLoading } = useQuery<EstimateReport>({
    queryKey,
    queryFn: async () => {
      const response = await apiClient.get(
        `/v1/past_report/getByEstimate/${estimateId}`,
        {
          params: {
            is_super_admin: userContext === AccountType.SUPERADMIN,
          },
        }
      );
      const responseData = decryptData(response.data);
      return mapEstimateReportResponse(responseData);
    },
  });

  const createQuickWorkOrderMutation = useMutation({
    mutationFn: async () => {
      if (!defaultWorkOrderStatus || !defaultWorkOrderStatus?.id) {
        throw new Error("Default work order status not found");
      }

      await apiClient.post<{ success: true }>(`/v1/work_orders/create`, {
        estimate_id: estimateId,
        status: defaultWorkOrderStatus.id,
      });
    },
    onSuccess: () => {
      queryClient.setQueryData<EstimateReport>(queryKey, (prev) => {
        if (!prev || !defaultWorkOrderStatus || !defaultWorkOrderStatus?.id) {
          return prev;
        }

        return {
          ...prev,
          workOrderStatus: defaultWorkOrderStatus.status,
          workOrderStatusBgColor: defaultWorkOrderStatus?.background_color,
          workOrderStatusTextColor: defaultWorkOrderStatus?.text_color,
        };
      });
    },
  });

  const downloadEstimateReportPdfMutation = useMutation({
    mutationFn: async () => {
      if (!data) {
        throw new Error("No estimate report data was found");
      }

      const res = await apiClient.get<Blob>(
        `/v1/past_report/fetchReportPdf/${estimateId}`,
        { responseType: "blob" }
      );

      return {
        blob: res.data,
        contentType: res.headers["content-type"],
      };
    },
  });

  return {
    report: data,
    isLoading,
    createQuickWorkOrderMutation: {
      mutateAsync: createQuickWorkOrderMutation.mutateAsync,
      isPending: createQuickWorkOrderMutation.isPending,
      error: createQuickWorkOrderMutation.error || undefined,
    },
    downloadEstimateReportPdfMutation: {
      /**
       * This mutation returns the blob and the content type of the PDF file,
       * intended to be used with the `saveAs` function from the `file-saver` package.
       */
      mutateAsync: downloadEstimateReportPdfMutation.mutateAsync,
      isPending: downloadEstimateReportPdfMutation.isPending,
      error: downloadEstimateReportPdfMutation.error || undefined,
    },
  };
}
