import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EditSensorResponse,
  ListSensorResponse,
  UpdateSensorsRequest,
} from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
const baseUrl = "/v1/sensors";

export type SensorFilterParams = {
  carId: string;
  name: string;
  makeId: string;
  model: string[];
  year: string[];
  locationIds: string[];
  categoryId: string;
};

export type SensorParams = SensorFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useSensors(
  sandboxId: string,
  params: SensorParams,
  onRefresh?: () => void
) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["sensors", sandboxId, JSON.stringify(params)];

  const queryCache = queryClient.getQueryCache();
  const revisionsKeys = queryCache
    .getAll()
    .map((cache) => cache.queryKey)
    .filter((key) => key[0] === "revisions");

  revisionsKeys.forEach((key) => queryClient.refetchQueries({ queryKey: key }));

  const { getPage, refetch, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchSensors(apiClient, sandboxId, params, pageNumber);

      return {
        items: res.sensors,
        count: res.count,
      };
    },
    onRefresh
  );

  const updateMutation = useMutation({
    mutationFn: async (payload: UpdateSensorsRequest) => {
      const queryParams = convertParams({
        ...params,
        sortBy: "",
        sortOrder: "",
      });

      const res = await apiClient.post<EditSensorResponse>(
        `${baseUrl}/${sandboxId}/update`,
        {
          entries: payload.edits,
          description: payload.description,
        },
        {
          params: queryParams,
        }
      );

      return res.data.message;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["revisions"],
        exact: false,
      });
      await refetch();
    },
  });

  return {
    getPage,
    count,
    pageSize,
    error,
    updateMutation: {
      mutateAsync: updateMutation.mutateAsync,
      isPending: updateMutation.isPending,
      error: updateMutation.error || undefined,
    },
  };
}

async function fetchSensors(
  apiClient: AxiosInstance,
  sandboxId: string,
  params: SensorParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<ListSensorResponse>(
    `${baseUrl}/${sandboxId}`,
    {
      params: queryParams,
    }
  );

  return res.data;
}

function convertParams(params: SensorParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("carId.equals", params.carId);
  queryParams.append("name.contains", params.name);
  queryParams.append("makeId.equals", params.makeId);

  if (params.model) {
    params.model.forEach((model) => queryParams.append("model.equals", model));
  }

  if (params.year) {
    params.year.forEach((year) => queryParams.append("year.equals", year));
  }

  if (params.locationIds) {
    params.locationIds.forEach((locationId) =>
      queryParams.append("locationIds.equals", locationId)
    );
  }

  if (params.categoryId) {
    queryParams.append("categoryId.equals", params.categoryId);
  }

  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
