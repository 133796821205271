import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EditInvoiceMutationResponse,
  EstimateReport,
  InvoiceCSVReportParams,
  InvoicesApiResponse,
  InvoicesConnectMutationResponse,
  InvoicesGetStripeCheckoutUrlsMutationResponse,
  InvoicesGetStripeLinkMutationResponse,
} from "@revv/data";

import { useStateContext } from "./context";
import { usePagedQuery } from "./usePagedQuery";

const pageSize = 20;
const baseUrl = "/v1/invoices";

type CreateInvoiceParams = {
  vin: string;
  shop_number: number;
  repair_order: string;
  status: string;
  primary_billing_email: string;
  secondary_billing_emails: string[];
  due_date: string;
  vehicle: string;
  email_id: string;
  shipping_name: string;
  shipping_street: string;
  shipping_city: string;
  shipping_state: string;
  shipping_postal_code: number | null;
  shipping_country: string;
  tracking_number: number | null;
  mileage: number | null;
};

export type InvoiceParams = {
  emailId: string;
  searchQuery?: string;
  startDateFilter?: string;
  endDateFilter?: string;
  shopFilters?: string[];
  technicianFilter?: string;
  sortBy?: string;
  sortOrder?: "asc" | "desc" | "";
};

type UseInvoiceParams = {
  params: InvoiceParams;
  decryptData: (data: string) => InvoicesApiResponse;
  onRefresh?: () => void;
};

type InvoicePage = {
  isConnectedToQuickbooks: boolean;
};

type HandleCSVDownloadMutationParams = {
  apiQueryParams: InvoiceCSVReportParams & {
    shop_filters?: string[];
  };
  fileName: string;
};

type GetStripeCheckoutUrlsMutationParams = {
  invoiceId: number;
  showDiscount: boolean;
};

type CreateFromEstimateMutationParams = {
  estimateReport: EstimateReport;
  getPrice: (data: EstimateReport) => {
    totalPrice: number;
    totalDiscount: number;
  };
};

function convertParams(params: InvoiceParams) {
  const queryParams = new URLSearchParams();

  if (params?.emailId) {
    queryParams.append("email_id", params.emailId);
  }

  if (params?.sortBy) {
    queryParams.append("sortBy", params.sortBy);
  }
  if (params?.sortOrder) {
    queryParams.append("order", params.sortOrder);
  }

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

async function fetchInvoices(
  apiClient: AxiosInstance,
  decryptData: (data: string) => InvoicesApiResponse,
  pageNumber: number,
  params: InvoiceParams
): Promise<InvoicesApiResponse | undefined> {
  if (!params.emailId) {
    return;
  }

  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  if (params.searchQuery && params?.searchQuery !== "") {
    queryParams.append("search_query", params.searchQuery);
  }

  if (params.startDateFilter) {
    queryParams.append("start_date_filter", params.startDateFilter);
  }

  if (params.endDateFilter) {
    queryParams.append("end_date_filter", params.endDateFilter);
  }

  if (params.shopFilters && params.shopFilters.length > 0) {
    params.shopFilters.forEach((value) =>
      queryParams.append("shop_filters[]", value)
    );
  }

  if (params.technicianFilter) {
    queryParams.append("technician_email_filter", params.technicianFilter);
  }

  const res = await apiClient.get(`${baseUrl}/all`, {
    params: queryParams,
  });

  const decryptedResponse = decryptData(res.data);
  return decryptedResponse;
}

export function useInvoices({
  params,
  decryptData,
  onRefresh,
}: UseInvoiceParams) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["invoices", JSON.stringify([params])];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const response = await fetchInvoices(
        apiClient,
        decryptData,
        pageNumber,
        params
      );

      return {
        items: response?.invoices ?? [],
        count: response?.totalCount ?? 0,
        isConnectedToQuickbooks: response?.isConnectedToQuickbooks ?? false,
      };
    },
    onRefresh
  );

  const getStripeLinkMutation = useMutation({
    mutationFn: async () => {
      const queryParams = convertParams({ emailId: params.emailId });
      const res = await apiClient.get<InvoicesGetStripeLinkMutationResponse>(
        `${baseUrl}/create_stripe_express_dashboard_link`,
        {
          params: queryParams,
        }
      );
      return res.data;
    },
  });

  const downloadCsvMutation = useMutation({
    mutationFn: async ({ apiQueryParams }: HandleCSVDownloadMutationParams) => {
      const res = await apiClient.get(`${baseUrl}/downloadCsv`, {
        params: apiQueryParams,
        responseType: "blob",
      });

      return res;
    },
  });

  const connectMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        email_id: params.emailId,
      };

      const res = await apiClient.post<InvoicesConnectMutationResponse>(
        `${baseUrl}/enable_stripe_connect`,
        payload
      );
      return res.data;
    },
  });

  const getStripeCheckoutUrlsMutation = useMutation({
    mutationFn: async ({
      invoiceId,
      showDiscount,
    }: GetStripeCheckoutUrlsMutationParams) => {
      const payload = {
        email_id: params.emailId,
        invoice_id: invoiceId,
        include_discount: showDiscount,
      };

      const res =
        await apiClient.get<InvoicesGetStripeCheckoutUrlsMutationResponse>(
          `${baseUrl}/stripe_checkout_urls`,
          {
            params: payload,
          }
        );

      return res;
    },
  });

  const createInvoiceMutation = useMutation({
    mutationFn: async (payload: CreateInvoiceParams) => {
      const res: { data: string } = await apiClient.post(
        `${baseUrl}/create-without-estimate`,
        payload
      );

      const decryptedData = decryptData(res.data);
      return decryptedData;
    },
  });

  const editInvoiceMutation = useMutation({
    mutationFn: async (payload: CreateInvoiceParams) => {
      const res = await apiClient.post<EditInvoiceMutationResponse>(
        `${baseUrl}/edit`,
        payload
      );

      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["invoices"],
        exact: false,
      });
    },
  });

  const createFromEstimateMutation = useMutation({
    mutationFn: async ({
      estimateReport,
      getPrice,
    }: CreateFromEstimateMutationParams) => {
      const payload = {
        estimate_id: estimateReport.estimateId,
        price: getPrice(estimateReport).totalPrice,
      };

      // The response is encrypted
      await apiClient.post<string>(
        "/v1/invoices/create_from_estimate",
        payload
      );

      return estimateReport.estimateId;
    },
    onSuccess: async (estimateId: number) => {
      await queryClient.invalidateQueries({
        queryKey: ["report", String(estimateId)],
        exact: false,
      });
    },
  });

  const editInvoiceNumberMutation = useMutation({
    mutationFn: async (payload: { increment_from?: number }) => {
      const res = await apiClient.post(`${baseUrl}/invoice_number`, payload);

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer"],
        exact: false,
      });
    },
  });

  const data = queryClient.getQueryData<InfiniteData<InvoicePage>>(queryKey);

  return {
    getPage,
    count,
    error,
    pageSize,
    isConnectedToQuickbooks: data?.pages?.at(-1)?.isConnectedToQuickbooks,
    getStripeLinkMutation: {
      mutateAsync: getStripeLinkMutation.mutateAsync,
      isPending: getStripeLinkMutation.isPending,
      error: getStripeLinkMutation.error || undefined,
    },
    downloadCsvMutation: {
      mutateAsync: downloadCsvMutation.mutateAsync,
      isPending: downloadCsvMutation.isPending,
      error: downloadCsvMutation.error || undefined,
    },
    connectMutation: {
      mutateAsync: connectMutation.mutateAsync,
      isPending: connectMutation.isPending,
      error: connectMutation.error || undefined,
    },
    getStripeCheckoutUrlsMutation: {
      mutateAsync: getStripeCheckoutUrlsMutation.mutateAsync,
      isPending: getStripeCheckoutUrlsMutation.isPending,
      error: getStripeCheckoutUrlsMutation.error || undefined,
    },
    createInvoiceMutation: {
      mutateAsync: createInvoiceMutation.mutateAsync,
      isPending: createInvoiceMutation.isPending,
      error: createInvoiceMutation.error || undefined,
    },
    editInvoiceMutation: {
      mutateAsync: editInvoiceMutation.mutateAsync,
      isPending: editInvoiceMutation.isPending,
      error: editInvoiceMutation.error || undefined,
    },
    createFromEstimateMutation: {
      mutateAsync: createFromEstimateMutation.mutateAsync,
      isPending: createFromEstimateMutation.isPending,
      error: createFromEstimateMutation.error || undefined,
    },
    editInvoiceNumberMutation: {
      mutateAsync: editInvoiceNumberMutation.mutateAsync,
      isPending: editInvoiceNumberMutation.isPending,
      error: editInvoiceNumberMutation.error || undefined,
    },
  };
}
