export type InvoiceRow = {
  account_id: number;
  id: number;
  status: string;
  price: number;
  work_order_id: string | undefined;
  created_at: string;
  due_date: string | undefined;
  primary_billing_email: string | undefined;
  secondary_billing_emails: string[];
  quickbooks_invoice_id: string | undefined;
  estimate_id: number;
  tax_percentage: string | undefined;
  last_email_sent: string | undefined;
  stripe_usage_record: string | undefined;
  shop_number: number;
  repair_order: string;
  vin: string;
  vehicle: string;
  estimator_name: string | undefined;
  shipping_name: string | undefined;
  shipping_street: string | undefined;
  shipping_city: string | undefined;
  shipping_state: string | undefined;
  shipping_postal_code: string | undefined;
  shipping_country: string | undefined;
  tracking_number: number | undefined;
  mileage: number | undefined;
  created_by: string;
  technician_id: string | undefined;
  edited_by_technician: boolean;
  last_edited_timestamp: string;
  invoice_number: number;
  shop_name: string;
  street: string | undefined;
  city: string | undefined;
  state: string | undefined;
  postal_code: undefined;
  country: string | undefined;
  gst_hst_number: string | undefined;
  technician_name: string | undefined;
  quickbooks_customer_id: string | undefined;
  discount_percentage: number | undefined;
  payment_terms: string | undefined;
  invoice_note: string | undefined;
  total_price: number | undefined;
  discount: number;
  report_json: object;
  edit_history: InvoiceEditHistoryRow[];
};

export type InvoiceEditHistoryRow = {
  account_type: string;
  authenticated_customer_id: string;
  authenticated_customer_name: string;
  customer_id: string;
  customer_name: string;
  edit_timestamp: string;
  email_id: string;
  operation_type: string;
};

export type InvoicesResponse = {
  items: InvoiceRow[];
  isConnectedToQuickbooks: boolean;
  count: number;
};

export type InvoicesApiResponse = {
  invoices: InvoiceRow[];
  isConnectedToQuickbooks: boolean;
  invoice: InvoiceRow;
  nextPageCount: number;
  totalCount: number;
};

export type InvoicesGetStripeLinkMutationResponse = {
  url: string;
  message: string;
};

export type InvoicesConnectMutationResponse = {
  connected: boolean;
  message: string;
  uri: string;
};

export type InvoicesGetStripeCheckoutUrlsMutationResponse = {
  stripe_cc_url: string | undefined;
  stripe_ach_url: string | undefined;
};

export type EditInvoiceMutationResponse = {
  invoice: InvoiceRow;
};

export enum InvoiceStatus {
  Archived = "archived",
  Open = "open",
  Overdue = "overdue",
  Paid = "paid",
}
