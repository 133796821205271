import { captureException } from "@sentry/react";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";

import { Shop, UserPermissions } from "@revv/data";

import { useStateContext } from "./context";
import { useCustomer } from "./useCustomer";

// Need to eventually update this hardcoded limit to be dynamic
const maxPageLength = 200;
const BASE_URL = "/v1/shop";

type ShopsQueryParams = {
  pageNumber: number;
  pageSize: number;
  filter: string;
};

type ShopsResponse = {
  shops: Shop[];
  nextPageCount: number;
};

type UpdateSettingsMutationResponse = {
  message: string;
};

type UseShopsOptions = {
  enablePlaceholderData?: boolean;
};

type ReportSettingsParams = {
  shop_number: number;
  show_estimated_price_on_report?: boolean;
  show_discount_on_report?: boolean;
  enable_sensor_trigger_disclaimer?: boolean;
  integrations_require_ro?: boolean;
};

type IntegrationSettingsParams = {
  shop_number: number;
  integrations_filter_makes?: string[];
  integrations_filter_years?: string[];
};

type NotificationSettingsParams = {
  shop_number?: number;
  master_email?: string;
  notification_settings?: object;
};

function convertParams(params: ShopsQueryParams) {
  const queryParams = new URLSearchParams();

  if (params?.pageNumber) {
    queryParams.append("pageNumber", params.pageNumber.toString());
  }

  if (params?.pageSize) {
    queryParams.append("pageSize", params.pageSize.toString());
  }

  queryParams.append("filter", params.filter);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

export function useShops(
  masterEmail = "",
  pageNumber = 1,
  pageSize = maxPageLength,
  filter = "",
  options: UseShopsOptions = {}
) {
  const { enablePlaceholderData = false } = options;

  const queryClient = useQueryClient();
  const { apiClient } = useStateContext();
  const { customerInfo } = useCustomer();

  const isUserAuthorized = useMemo(
    () => customerInfo?.permissions?.includes(UserPermissions.VIEW_ALL_SHOPS),
    [customerInfo]
  );

  const { data, refetch, error, isLoading } = useQuery<ShopsResponse, Error>({
    refetchOnWindowFocus: false,
    queryKey: ["shops", masterEmail, pageNumber, pageSize, filter],
    queryFn: async () => {
      try {
        const queryParams = convertParams({ pageNumber, pageSize, filter });
        const response = await apiClient.get<ShopsResponse>(
          `${BASE_URL}/getAllShops/${masterEmail}`,
          {
            params: queryParams,
          }
        );

        if (response.data.shops.length === maxPageLength) {
          captureException("Reached max shops limit");
        }
        return response.data;
      } catch (error: unknown) {
        throw error instanceof AxiosError
          ? new Error(error.response?.data.error)
          : new Error("Something went wrong");
      }
    },
    enabled: isUserAuthorized,
    placeholderData: enablePlaceholderData ? keepPreviousData : undefined,
  });

  const integrationSettingsMutation = useMutation({
    mutationFn: async (payload: IntegrationSettingsParams) => {
      const res = await apiClient.post<UpdateSettingsMutationResponse>(
        `${BASE_URL}/updateIntegrationsMakesYears`,
        payload
      );
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shops"],
        exact: false,
      });
    },
  });

  const reportSettingsMutation = useMutation({
    mutationFn: async (payload: ReportSettingsParams) => {
      const payloadKeys = Object.keys(payload);

      let settingRoute;
      if (payloadKeys.includes("show_estimated_price_on_report")) {
        settingRoute = "enableDisableShowEstimatedPrice";
      } else if (payloadKeys.includes("show_discount_on_report")) {
        settingRoute = "enableDisableShowDiscount";
      } else if (payloadKeys.includes("enable_sensor_trigger_disclaimer")) {
        settingRoute = "enableDisableSensorTriggerDisclaimer";
      } else if (payloadKeys.includes("integrations_require_ro")) {
        settingRoute = "enableDisableIntegrationsRequireRO";
      }

      if (!settingRoute) {
        return;
      }

      const res = await apiClient.post<UpdateSettingsMutationResponse>(
        `${BASE_URL}/${settingRoute}`,
        payload
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shops"],
        exact: false,
      });
    },
  });

  const updateShopNotificationsMutation = useMutation({
    mutationFn: async (payload: NotificationSettingsParams) => {
      await apiClient.post(
        `${BASE_URL}/updateIntegrationEstimatorNotifications`,
        payload
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shops"],
        exact: false,
      });
    },
  });

  return {
    shops: isUserAuthorized ? data?.shops : customerInfo?.shops,
    nextPageCount: data?.nextPageCount,
    isLoading,
    shopsError: error,
    refetch,
    integrationSettingsMutation: {
      mutateAsync: integrationSettingsMutation.mutateAsync,
      isPending: integrationSettingsMutation.isPending,
      error: integrationSettingsMutation.error || undefined,
    },
    reportSettingsMutation: {
      mutateAsync: reportSettingsMutation.mutateAsync,
      isPending: reportSettingsMutation.isPending,
      error: reportSettingsMutation.error || undefined,
    },
    updateShopNotificationsMutation: {
      mutateAsync: updateShopNotificationsMutation.mutateAsync,
      isPending: updateShopNotificationsMutation.isPending,
      error: updateShopNotificationsMutation.error || undefined,
    },
  };
}
