import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  QuickbooksConnectMutationResponse,
  QuickbooksSendInvoiceMutationResponse,
} from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/quickbooks";

type QuickbooksConnectParams = {
  selectedMaster?: string;
  redirectUrl?: string;
};

function convertParams(params: QuickbooksConnectParams) {
  const queryParams = new URLSearchParams();

  if (params?.selectedMaster) {
    queryParams.append("email", params.selectedMaster);
  }

  if (params?.redirectUrl) {
    queryParams.append("redirect_url", params.redirectUrl);
  }

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

export function useQuickbooks() {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const connectMutation = useMutation({
    mutationFn: async ({
      selectedMaster,
      redirectUrl,
    }: QuickbooksConnectParams) => {
      const queryParams = convertParams({
        selectedMaster,
        redirectUrl,
      });
      const res = await apiClient.get<QuickbooksConnectMutationResponse>(
        `${BASE_URL}/connect_to_quickbooks`,
        {
          params: queryParams,
        }
      );
      return res.data;
    },
  });

  const disconnectMutation = useMutation({
    mutationFn: async () => {
      await apiClient.post(`${BASE_URL}/disconnect`);
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoices"],
        exact: false,
      });
    },
  });

  const sendInvoiceMutation = useMutation({
    mutationFn: async (invoices: number[]) => {
      const payload = {
        invoice_ids: invoices,
      };
      const res = await apiClient.post<QuickbooksSendInvoiceMutationResponse>(
        `${BASE_URL}/send-invoice`,
        payload
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoices"],
        exact: false,
      });
    },
  });

  return {
    connectMutation: {
      mutateAsync: connectMutation.mutateAsync,
      isPending: connectMutation.isPending,
      error: connectMutation.error || undefined,
    },
    disconnectMutation: {
      mutateAsync: disconnectMutation.mutateAsync,
      isPending: disconnectMutation.isPending,
      error: disconnectMutation.error || undefined,
    },
    sendInvoiceMutation: {
      mutateAsync: sendInvoiceMutation.mutateAsync,
      isPending: sendInvoiceMutation.isPending,
      error: sendInvoiceMutation.error || undefined,
    },
  };
}
