import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  RateSheetResponseType,
  RateSheetServiceCreateData,
  RateSheetServicesResponse,
  ServiceOperationType,
  ServiceType,
} from "@revv/data";
import {
  RateSheetService,
  RateSheetType,
  UpdateRateSheetServiceResponse,
} from "@revv/data";

import { useStateContext } from "./context";

export type UseRateSheetServicesParams = {
  selectedMakeId: RateSheetService["autoMakeId"] | undefined;
  selectedInsuranceId: RateSheetService["insuranceId"] | undefined;
  selectedType: RateSheetType;
  selectedShopId: RateSheetService["shopId"] | undefined;
  adasLoadLimit: number;
  functionalLoadLimit: number;
  safetyLoadLimit: number;
  steeringLoadLimit: number;
};

export type FormattedRateSheetData = {
  [ServiceOperationType.ADAS]: {
    services: RateSheetService[];
    hasMore: boolean;
  };
  [ServiceOperationType.FUNCTIONAL]: {
    services: RateSheetService[];
    hasMore: boolean;
  };
  [ServiceOperationType.SAFETY]: {
    services: RateSheetService[];
    hasMore: boolean;
  };
  [ServiceOperationType.STEERING]: {
    services: RateSheetService[];
    hasMore: boolean;
  };
};

function formatServices(
  data: RateSheetResponseType | undefined
): FormattedRateSheetData {
  const formattedData: FormattedRateSheetData = {
    [ServiceOperationType.ADAS]: {
      services: [],
      hasMore: false,
    },
    [ServiceOperationType.STEERING]: {
      services: [],
      hasMore: false,
    },
    [ServiceOperationType.SAFETY]: {
      services: [],
      hasMore: false,
    },
    [ServiceOperationType.FUNCTIONAL]: {
      services: [],
      hasMore: false,
    },
  };
  if (!data) {
    return formattedData;
  }

  data.rateSheetServices.forEach((service) => {
    formattedData[service.operationType].services.push(service);
  });
  formattedData[ServiceOperationType.ADAS].hasMore = data.adasHasMore;
  formattedData[ServiceOperationType.STEERING].hasMore = data.steeringHasMore;
  formattedData[ServiceOperationType.SAFETY].hasMore = data.safetyHasMore;
  formattedData[ServiceOperationType.FUNCTIONAL].hasMore =
    data.functionalHasMore;

  return formattedData;
}

export function useRateSheetServices({
  selectedMakeId,
  selectedInsuranceId,
  selectedType,
  selectedShopId,
  adasLoadLimit,
  functionalLoadLimit,
  safetyLoadLimit,
  steeringLoadLimit,
}: UseRateSheetServicesParams) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = [
    "AutoQuoting",
    "RateSheetServices",
    selectedMakeId,
    selectedInsuranceId,
    selectedType,
    selectedShopId,
    adasLoadLimit,
    functionalLoadLimit,
    steeringLoadLimit,
    safetyLoadLimit,
  ];

  const { data, error, isLoading, refetch } =
    useQuery<RateSheetServicesResponse>({
      queryKey,
      queryFn: async () => {
        if (selectedShopId === undefined || selectedType === undefined) {
          const defaultReturn: RateSheetServicesResponse = {
            [ServiceType.DEFAULT]: {
              rateSheetServices: [],
              adasHasMore: false,
              functionalHasMore: false,
              safetyHasMore: false,
              steeringHasMore: false,
            },
            [ServiceType.CUSTOM]: {
              rateSheetServices: [],
              adasHasMore: false,
              functionalHasMore: false,
              safetyHasMore: false,
              steeringHasMore: false,
            },
          };

          return defaultReturn;
        }

        const { data } = await apiClient.get<RateSheetServicesResponse>(
          `/v1/rateSheets/queryRateSheets/${selectedShopId}`,
          {
            params: {
              type: selectedType,
              shopId: selectedShopId,
              insuranceId: selectedInsuranceId,
              makeId: selectedMakeId,
              adasLoadLimit: adasLoadLimit,
              functionalLoadLimit: functionalLoadLimit,
              safetyLoadLimit: safetyLoadLimit,
              steeringLoadLimit: steeringLoadLimit,
            },
          }
        );
        return data;
      },
    });

  function updateServiceData(serviceData: RateSheetService) {
    queryClient.setQueryData<RateSheetServicesResponse>(queryKey, (data) => {
      if (!data) {
        return;
      }

      return {
        ...data,
        [serviceData.serviceType]: {
          ...data[serviceData.serviceType],
          rateSheetServices: data[serviceData.serviceType][
            "rateSheetServices"
          ].map((service: RateSheetService) => {
            if (service.id === serviceData.id) {
              return { ...serviceData, type: service.type, active: true };
            }
            return service;
          }),
        },
      };
    });
  }

  function activateServiceData() {
    queryClient.setQueryData<RateSheetServicesResponse>(queryKey, (data) => {
      if (!data) {
        return;
      }

      return {
        [ServiceType.DEFAULT]: {
          ...data[ServiceType.DEFAULT],
          rateSheetServices: data[ServiceType.DEFAULT].rateSheetServices.map(
            (rss) => {
              return {
                ...rss,
                active: true,
              };
            }
          ),
        },
        [ServiceType.CUSTOM]: {
          ...data[ServiceType.CUSTOM],
          rateSheetServices: data[ServiceType.CUSTOM].rateSheetServices.map(
            (rss) => {
              return {
                ...rss,
                active: true,
              };
            }
          ),
        },
      };
    });
  }

  function addServiceData(serviceData: RateSheetService) {
    queryClient.setQueryData<RateSheetServicesResponse>(queryKey, (data) => {
      if (!data) {
        return;
      }

      const newServiceArray: RateSheetService[] =
        data[serviceData.serviceType].rateSheetServices;
      newServiceArray.push(serviceData);

      return {
        ...data,
        [serviceData.serviceType]: {
          ...data[serviceData.serviceType],
          rateSheetServices: newServiceArray,
        },
      };
    });
  }

  function deleteServiceData(serviceData: RateSheetService) {
    queryClient.setQueryData<RateSheetServicesResponse>(queryKey, (data) => {
      if (!data) {
        return;
      }
      // copy since react-query doesnt consider direct splice by reference an update
      const newServiceArray = JSON.parse(
        JSON.stringify(data[serviceData.serviceType].rateSheetServices)
      );
      newServiceArray.splice(
        data[serviceData.serviceType].rateSheetServices.findIndex(
          (service: RateSheetService) => service.id === serviceData.id
        ),
        1
      );

      return {
        ...data,
        [serviceData.serviceType]: {
          ...data[serviceData.serviceType],
          rateSheetServices: newServiceArray,
        },
      };
    });
  }

  const updateServiceMutation = useMutation({
    mutationFn: async (service: RateSheetService) => {
      const { data } = await apiClient.post<UpdateRateSheetServiceResponse>(
        "/v1/services/updateService",
        {
          ...service,
          makes: [],
        }
      );

      return data.service;
    },
    onSuccess: (service) => {
      updateServiceData(service);
    },
  });

  const createServiceMutation = useMutation({
    mutationFn: async (serviceData: RateSheetServiceCreateData) => {
      const { data } = await apiClient.post<UpdateRateSheetServiceResponse>(
        "/v1/services/createService",
        {
          ...serviceData,
          shopId: selectedShopId,
        }
      );

      return {
        ...data.service,
        type: serviceData.type,
      };
    },
    onSuccess: (service) => {
      addServiceData(service);
    },
  });

  const deleteServiceMutation = useMutation({
    mutationFn: async (service: RateSheetService) => {
      await apiClient.post<UpdateRateSheetServiceResponse>(
        "/v1/services/deleteService",
        service
      );
      return service;
    },
    onSuccess: (service) => {
      deleteServiceData(service);
    },
  });

  const activeRateSheetMutation = useMutation({
    mutationFn: async (activateData: {
      selectedShopId: UseRateSheetServicesParams["selectedShopId"];
      selectedInsuranceId: UseRateSheetServicesParams["selectedInsuranceId"];
      selectedMakeId: UseRateSheetServicesParams["selectedMakeId"];
      selectedType: UseRateSheetServicesParams["selectedType"];
    }) => {
      await apiClient.post("/v1/rateSheets/activateRateSheet", {
        shopId: activateData.selectedShopId,
        insuranceId: activateData.selectedInsuranceId,
        makeId: activateData.selectedMakeId,
        type: activateData.selectedType,
      });
    },
    onSuccess: () => {
      activateServiceData();
    },
  });

  const importFromShopMutation = useMutation({
    mutationFn: async (importData: {
      importFromShopId: RateSheetService["shopId"];
      importToShopId: RateSheetService["shopId"];
    }) => {
      const { data } = await apiClient.post("/v1/rateSheets/importFromShop", {
        importFromShopId: importData.importFromShopId,
        importToShopId: importData.importToShopId,
        makeId: selectedMakeId,
        type: selectedType,
        adasLoadLimit: adasLoadLimit,
        functionalLoadLimit: functionalLoadLimit,
        steeringLoadLimit: steeringLoadLimit,
        safetyLoadLimit: safetyLoadLimit,
      });

      return data;
    },
    onSuccess: (updatedData: RateSheetServicesResponse) => {
      queryClient.setQueryData<RateSheetServicesResponse>(queryKey, (data) => {
        if (!updatedData) {
          return data;
        }
        return updatedData;
      });
    },
  });

  type DuplicateMakeRatesMutationPayload = {
    shopId: RateSheetService["shopId"];
    makeToCopyFrom: RateSheetService["autoMakeId"];
    makesToCopyTo: RateSheetService["autoMakeId"][];
    servicesToCopy: ServiceType[];
    rateSheetType: RateSheetService["type"];
    insuranceId: RateSheetService["insuranceId"];
  };

  const duplicateMakeRatesMutation = useMutation({
    mutationFn: async (duplicateData: DuplicateMakeRatesMutationPayload) => {
      await apiClient.post("/v1/rateSheets/duplicateMakeRates", duplicateData);
    },
    onSuccess: () => {
      refetch();
    },
  });

  type DuplicateInsuranceRatesMutationPayload = {
    shopId: RateSheetService["shopId"];
    insuranceToCopyFrom: RateSheetService["insuranceId"];
    insurancesToCopyTo: RateSheetService["insuranceId"][];
    servicesToCopy: ServiceType[];
    rateSheetType: RateSheetService["type"];
    makeId: RateSheetService["autoMakeId"];
  };

  const duplicateInsuranceRatesMutation = useMutation({
    mutationFn: async (
      duplicateData: DuplicateInsuranceRatesMutationPayload
    ) => {
      await apiClient.post(
        "/v1/rateSheets/duplicateInsuranceRates",
        duplicateData
      );
    },
    onSuccess: () => {
      refetch();
    },
  });

  return {
    ...data,
    rateSheetId:
      data?.[ServiceType.DEFAULT].rateSheetServices?.[0]?.rateSheetId,
    isActive:
      data?.[ServiceType.DEFAULT].rateSheetServices?.[0]?.active || false,
    defaultServices: formatServices(data?.[ServiceType.DEFAULT]),
    customServices: formatServices(data?.[ServiceType.CUSTOM]),
    error,
    isLoading,
    updateRateSheetService: async (service: RateSheetService) => {
      await updateServiceMutation.mutateAsync(service);
    },
    createRateSheetService: async (service: RateSheetServiceCreateData) => {
      await createServiceMutation.mutateAsync(service);
    },
    deleteRateSheetService: async (service: RateSheetService) => {
      await deleteServiceMutation.mutateAsync(service);
    },
    setActiveRateSheet: async (
      selectedShopId: UseRateSheetServicesParams["selectedShopId"],
      selectedInsuranceId: UseRateSheetServicesParams["selectedInsuranceId"],
      selectedMakeId: UseRateSheetServicesParams["selectedMakeId"],
      selectedType: UseRateSheetServicesParams["selectedType"]
    ) => {
      await activeRateSheetMutation.mutateAsync({
        selectedShopId: selectedShopId,
        selectedInsuranceId: selectedInsuranceId,
        selectedMakeId: selectedMakeId,
        selectedType: selectedType,
      });
    },
    importFromShop: async (
      importFromShopId: RateSheetService["shopId"],
      importToShopId: RateSheetService["shopId"]
    ) => {
      await importFromShopMutation.mutateAsync({
        importFromShopId: importFromShopId,
        importToShopId: importToShopId,
      });
    },
    duplicateMakeRates: async (
      shopId: RateSheetService["shopId"],
      makeToCopyFrom: RateSheetService["autoMakeId"],
      makesToCopyTo: RateSheetService["autoMakeId"][],
      servicesToCopy: ServiceType[],
      rateSheetType: RateSheetService["type"],
      insuranceId: RateSheetService["insuranceId"]
    ) => {
      await duplicateMakeRatesMutation.mutateAsync({
        shopId: shopId,
        makeToCopyFrom: makeToCopyFrom,
        makesToCopyTo: makesToCopyTo,
        servicesToCopy: servicesToCopy,
        rateSheetType: rateSheetType,
        insuranceId: insuranceId,
      });
    },
    duplicateInsuranceRates: async (
      duplicatePayload: DuplicateInsuranceRatesMutationPayload
    ) => {
      await duplicateInsuranceRatesMutation.mutateAsync(duplicatePayload);
    },
  };
}
