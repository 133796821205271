import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useStateContext } from "./context";

const BASE_URL = "/v1/preferences";

type NotificationMutationParams = {
  shop_number?: number;
  master_email?: string;
  notification_settings?: object;
};

export function usePreferences() {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const updatePreferenceNotificationsMutation = useMutation({
    mutationFn: async (payload: NotificationMutationParams) => {
      const res = await apiClient.post(
        `${BASE_URL}/updateNotificationSettings`,
        payload
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer"],
        exact: false,
      });
    },
  });

  return {
    updatePreferenceNotificationsMutation: {
      mutateAsync: updatePreferenceNotificationsMutation.mutateAsync,
      isPending: updatePreferenceNotificationsMutation.isPending,
      error: updatePreferenceNotificationsMutation.error || undefined,
    },
  };
}
