import { useQuery } from "@tanstack/react-query";

import { InvoiceStatus } from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/invoices";

export type InvoiceStatusResponse = {
  statuses: InvoiceStatus[];
};

export function useInvoiceStatuses() {
  const { apiClient } = useStateContext();

  const { data, refetch, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["invoice-statuses"],
    queryFn: async () => {
      const response = await apiClient.get<InvoiceStatusResponse>(
        `${BASE_URL}/statuses`
      );

      return {
        data: response.data.statuses,
      };
    },
  });

  return {
    invoiceStatuses: data?.data,
    refetch,
    error,
    isLoading,
  };
}
