import { useQuery } from "@tanstack/react-query";

import { PositionStatement } from "@revv/data";

import { useStateContext } from "./context";

type UsePositionStatementsParams = {
  make: string;
};

export function usePositionStatements({ make }: UsePositionStatementsParams) {
  const { apiClient } = useStateContext();

  const { data, isLoading } = useQuery({
    queryKey: ["positionStatements", make],
    queryFn: async () => {
      const { data } = await apiClient.get<PositionStatement[]>(
        `/v1/position_statements/${make}`
      );
      return data;
    },
  });

  return {
    positionStatements: data ?? [],
    isLoading,
  };
}
