import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  ListSafetyProceduresResponse,
  UpdateSafetyProceduresRequest,
  UpdateSafetyProceduresResponse,
} from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
const baseUrl = "/v1/safety-procedures";

export type SafetyProcedureFilterParams = {
  carId: string;
  name: string;
  rawName: string;
  makeId: string;
  model: string[];
  year: string[];
};

export type SafetyProcedureParams = SafetyProcedureFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useSafetyProcedures(
  sandboxId: string,
  params: SafetyProcedureParams,
  onRefresh?: () => void
) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["safety-procedures", sandboxId, JSON.stringify(params)];

  const { getPage, refetch, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchSafetyProcedures(
        apiClient,
        sandboxId,
        params,
        pageNumber
      );

      return {
        items: res.safetyProcedures,
        count: res.count,
      };
    },
    onRefresh
  );

  const updateMutation = useMutation({
    mutationFn: async (payload: UpdateSafetyProceduresRequest) => {
      const queryParams = convertParams({
        ...params,
        sortBy: "",
        sortOrder: "",
      });

      const res = await apiClient.post<UpdateSafetyProceduresResponse>(
        `${baseUrl}/${sandboxId}`,
        payload,
        {
          params: queryParams,
        }
      );

      return res.data.message;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["revisions"],
        exact: false,
      });
      await refetch();
    },
  });

  return {
    getPage,
    count,
    pageSize,
    error,
    updateMutation: {
      mutateAsync: updateMutation.mutateAsync,
      isPending: updateMutation.isPending,
      error: updateMutation.error || undefined,
    },
  };
}

async function fetchSafetyProcedures(
  apiClient: AxiosInstance,
  sandboxId: string,
  params: SafetyProcedureParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<ListSafetyProceduresResponse>(
    `${baseUrl}/${sandboxId}`,
    {
      params: queryParams,
    }
  );

  return res.data;
}

function convertParams(params: SafetyProcedureParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("carId.equals", params.carId);
  queryParams.append("name.contains", params.name);
  queryParams.append("rawName.contains", params.rawName);
  queryParams.append("makeId.equals", params.makeId);

  if (params.model) {
    params.model.forEach((model) => queryParams.append("model.equals", model));
  }

  if (params.year) {
    params.year.forEach((year) => queryParams.append("year.equals", year));
  }

  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
